import { useEffect, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { EmptyState } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { GroupsService } from '_common/services';
import { paths } from '_types/api';

import { listObjects } from '_common/components/Table/TableSlice';

import { GroupAvatar } from '_common/components';
import { ColumnProps } from 'dodoc-design-system/build/types/Components/Table/Table';
import Cell from '_common/components/Table/Cells';
import PermissionsCell from './components/PermissionsCell';
import MemberCell from './components/MemberCell';
import UserNameCell from 'Storage/pages/RecyclePage/components/Cells/UserNameCell';
import { ThunksTable } from '_common/components/Table';
import Footer from '_common/components/Table/Footer/Footer';
import SelectionCounter from '_common/components/Table/Footer/SelectionCounter';

import styles from './GroupsPage.module.scss';

const PAGE_IDENTITY = 'groups';

type ListProps = {
  onPermissionsChange: (
    permissions: paths['/api/object/group/list']['get']['responses']['200']['content']['multipart/form-data']['permissions'],
  ) => void;
};

const List = ({ onPermissionsChange }: ListProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const list = useSelector((state) => state.table.identity.groups.list);
  const appData = useSelector((state) => state.app.data);

  //#region Table object listing handlers
  const fetchObjects = useCallback(async (parameters: any) => {
    const resp = await new GroupsService().getGroupsList(parameters);
    onPermissionsChange(resp.data.permissions);
    return resp;
  }, []);

  useEffect(() => {
    dispatch(
      listObjects({
        identity: PAGE_IDENTITY,
        fetch: fetchObjects,
        cause: 'INITIAL',
        request: { offset: 0 },
      }),
    );
  }, []);

  // No useEffectOnUpdate since fetchObjects doesn't have any dependencies
  // Add if dependencies are added to fetchObjects

  //#endregion

  const columns = useMemo<ColumnProps[]>(() => {
    return [
      {
        id: 'name',
        header: intl.formatMessage({ id: 'groups.header.name' }),
        orderable: true,
        flex: true,
        width: 296,
      },
      {
        id: 'permissions',
        header: intl.formatMessage({ id: 'groups.header.permissions' }),
        width: 160,
      },
      {
        id: 'owner',
        header: intl.formatMessage({ id: 'groups.header.owner' }),
        width: 160,
      },
      {
        id: 'member',
        header: intl.formatMessage({ id: 'groups.header.member' }, { membersCount: 2 }),
        width: 160,
      },
    ];
  }, []);

  const value = useMemo(() => {
    return list.map((objectId) => {
      const object = appData[objectId];
      return {
        id: object.id,
        name: (
          <Cell testId={`${objectId}-name-column`} ellipsis>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
              <div>
                <GroupAvatar
                  margin="0 2rem 0 0"
                  text={object.name.substring(0, 2).toLowerCase()}
                  size="medium"
                  color="blue"
                />
              </div>
              <div className={`${styles.name}`}>{object.name}</div>
            </div>
          </Cell>
        ),
        permissions: (
          <Cell testId={`${objectId}-permissions-column`} ellipsis>
            <PermissionsCell object={object} />
          </Cell>
        ),
        owner: (
          <Cell testId={`${objectId}-owner-column`} ellipsis>
            <UserNameCell userId={object.owner} />
          </Cell>
        ),
        member: (
          <Cell testId={`${objectId}-members-column`} ellipsis>
            <MemberCell object={object} />
          </Cell>
        ),
      };
    });
  }, [list, appData]);

  if (list.length === 0) {
    return (
      <div className={styles.emptyView}>
        <EmptyState
          size="large"
          icon="NoGroups"
          title={intl.formatMessage({ id: 'groups.emptyView.title' })}
          testId="groups-empty-list"
        >
          {intl.formatMessage({ id: 'groups.emptyView.subtitle' })}
        </EmptyState>
      </div>
    );
  }

  return (
    <div className={styles.tableWrapper}>
      <ThunksTable
        identity={PAGE_IDENTITY}
        fetchObjects={fetchObjects}
        columns={columns}
        value={value}
        lazyLoading
        renderFooter={() => (
          <Footer>
            <SelectionCounter identity={PAGE_IDENTITY} />
          </Footer>
        )}
        loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
        testId="groups"
      />
    </div>
  );
};

export default List;
