import PopperTooltip from '../List/PopperTooltip/PopperTooltip';
import dayjs from 'dayjs';
import styles from '../List/List.module.scss';

type DateCellProps = {
  user: UserPublicProfileExtended;
};

const DateCell = ({ user }: DateCellProps) => {
  return (
    <div className={`${!user.is_active && styles.deactivated}`}>
      <PopperTooltip
        content={dayjs(user.date_joined).format('DD/MM/YYYY hh:mm A')}
        testId="item-dateJoined-tooltip"
      >
        <>{dayjs(user.date_joined).format('DD/MM/YYYY hh:mm A')}</>
      </PopperTooltip>
    </div>
  );
};

export default DateCell;
