import { useSelector } from '_common/hooks';
import { useIntl } from 'react-intl';
import { Status } from '_common/components';

import {
  selectOrderedElementStatusList,
  useGetElementStatusListQuery,
} from '_common/services/api/elementStatusApi';
import styles from './ElementStatusTable.module.scss';
import { useMemo } from 'react';
import Cell from '_common/components/Table/Cells';
import { Icon } from 'dodoc-design-system';
import { Table } from '_common/components/Table';
import StatusSuffixCell from './Cells/StatusSuffixCell';
import MoveIconsCell from './Cells/MoveIconsCell';
import { ColumnProps } from 'dodoc-design-system/build/types/Components/Table/Table';

const ElementStatusTable = () => {
  const intl = useIntl();
  const { data } = useGetElementStatusListQuery();
  const list = useSelector(selectOrderedElementStatusList);

  const columns = useMemo<ColumnProps[]>(() => {
    return [
      {
        id: 'status',
        header: intl.formatMessage({ id: 'STATUS' }),
        width: 200,
        flex: true,
      },
      {
        id: 'edit',
        header: intl.formatMessage({ id: 'global.edit' }),
        width: 104,
      },
      {
        id: 'move',
        header: intl.formatMessage({ id: 'global.move' }),
        width: 104,
      },
      {
        id: 'delete',
        header: intl.formatMessage({ id: 'global.delete' }),
        width: 104,
      },
      {
        id: 'visible_status',
        header: intl.formatMessage({ id: 'VISIBLE_STATUS' }),
        width: 128,
      },
      {
        id: 'confirmation_request',
        header: intl.formatMessage({ id: 'CONFIRMATION_REQUEST' }),
        width: 160,
      },
      {
        id: 'move_icons',
        width: 104,
      },
    ];
  }, []);

  const value = useMemo(() => {
    return list.map((status, index) => {
      return {
        id: status.id,
        status: (
          <Cell testId={`${status.name.toLowerCase()}-status-column`} ellipsis>
            <Status id={status.id} testId="status-label" />
          </Cell>
        ),
        edit: (
          <Cell testId={`${status.name.toLowerCase()}-edit-column`}>
            <Icon icon={status.allow_edit ? 'Done' : 'RemoveLightRed'} size={24} />
          </Cell>
        ),
        move: (
          <Cell testId={`${status.name.toLowerCase()}-move-column`}>
            <Icon icon={status.allow_move ? 'Done' : 'RemoveLightRed'} size={24} />
          </Cell>
        ),
        delete: (
          <Cell testId={`${status.name.toLowerCase()}-delete-column`}>
            <Icon icon={status.allow_delete ? 'Done' : 'RemoveLightRed'} size={24} />
          </Cell>
        ),
        visible_status: (
          <Cell testId={`${status.name.toLowerCase()}-visible-status-column`}>
            <Icon icon={status.visible ? 'Done' : 'RemoveLightRed'} size={24} />
          </Cell>
        ),
        confirmation_request: (
          <Cell testId={`${status.name.toLowerCase()}-confirmation-request-column`}>
            <Icon icon={status.confirm_input ? 'Done' : 'RemoveLightRed'} size={24} />
          </Cell>
        ),
        move_icons: (
          <Cell testId={`${status.name.toLowerCase()}-move-icons-column`}>
            <MoveIconsCell status={status} index={index} />
          </Cell>
        ),
        suffix: (
          <Cell testId={`${status.name.toLowerCase()}-suffix-column`}>
            <StatusSuffixCell status={status} />
          </Cell>
        ),
      };
    });
  }, [list, data]);

  return (
    <div className={styles.content}>
      <div className={styles.tableWrapper}>
        <Table
          identity="status"
          selectable={false}
          columns={columns}
          value={value}
          renderFooter={() => null}
          isLoading={false}
          loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
          testId="status"
        />
      </div>
    </div>
  );
};

export default ElementStatusTable;
