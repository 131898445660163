import { useState, useEffect, EffectCallback } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputField, PasswordInput, Button, Popover, usePopper } from 'dodoc-design-system';

import { navigateToSignIn } from 'router/history';
import { Logger, AuthService } from '_common/services';
import { useDispatch } from '_common/hooks';
import type { paths } from '_types/authority';

import { gotFeedback } from 'Auth/redux/authSlice';

import { PasswordCheck, LoadingPage } from '_common/components';
import SecondaryAuthPageContainer from 'Auth/components/SecondaryAuthPageContainer/SecondaryAuthPageContainer';

export const TEST_IDS = {
  INPUT: {
    NEW_PASSWORD: 'newPassword',
    CONFIRM_PASSWORD: 'confirmPassword',
  },
  BUTTON: 'button',
};

const ResetPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { hash } = useParams<RouterParams['resetPage']>();

  const [submitting, setSubmitting] = useState(false);
  const [rules, setRules] = useState<PasswordValidators>([]);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [newPassword, setNewPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [validations, setValidations] = useState({
    newPassword: '',
    confirmedPassword: '',
  });
  const { popperProps, referenceProps } = usePopper({
    placement: 'left-start',
    trigger: 'focus',
    disabled: rules.length === 0,
    skidding: 5,
    distance: 30,
  });

  useEffect((): ReturnType<EffectCallback> => {
    dispatch(gotFeedback(null));

    (async function getRecoverValidators() {
      try {
        const response = await new AuthService({ errorsExpected: [400, 404] }).getRecoverValidators(
          hash,
        );

        //@ts-expect-error Endpoint type issue: /api/authority/account/password/recover/{hash}/validators should also return 'validators'
        setRules(response.data.validators);
        setLoading(false);
      } catch (error) {
        if (AuthService.isAxiosError(error)) {
          if (error?.response?.status === 400) {
            const errors = error.response.data.errors[0];
            const key = errors.errors[0];
            if (errors.field === 'hash' && key === 'invalid') {
              goToSignIn();
            }
          } else if (error?.response?.status === 404) {
            setRules([]);
            setLoading(false);
          } else {
            goToSignIn();
          }
        }
      }
    })();

    return (): void => {
      dispatch(gotFeedback(null));
    };
  }, []);

  useEffect(() => {
    if (!rules || rules.length === 0) {
      setDisabled(false);
    }
  }, [rules]);

  const VALUES_FOR_FEEDBACK = {
    username: 'settings.user.username',
    first_name: 'settings.name.firstName',
    last_name: 'settings.name.lastName',
    email_address: 'editor.templatePreview.properties.authors.email',
  };

  const handleSetDisabled = (isDisabled: boolean) => {
    if (isDisabled) {
      setDisabled(true);
    } else if (disabled === true) {
      setDisabled(false);
      setValidations({ ...validations, newPassword: '' });
    }
  };

  const handleInputChange = (value: string, name: 'newPassword' | 'confirmedPassword') => {
    const newValidations = { ...validations };
    if (name === 'newPassword') {
      setNewPassword(value);
      if (value !== confirmedPassword && confirmedPassword !== '') {
        newValidations.confirmedPassword = intl.formatMessage({
          id: 'ERROR_PASSWORDS_DO_NOT_MATCH',
        });
      } else {
        newValidations.confirmedPassword = '';
      }
    } else {
      setConfirmedPassword(value);
      if (value === newPassword) {
        newValidations.confirmedPassword = '';
      } else if (value !== newPassword) {
        newValidations.confirmedPassword = intl.formatMessage({
          id: 'ERROR_PASSWORDS_DO_NOT_MATCH',
        });
      }
    }
    if (value === '') {
      newValidations[name] = intl.formatMessage({ id: 'ERROR_REQUIRED_INPUT' });
    } else {
      newValidations[name] = '';
    }
    setValidations(newValidations);
  };

  const goToSignIn = () => {
    navigateToSignIn();
  };

  const handleChangePassword = async () => {
    if (!disabled && newPassword === confirmedPassword) {
      setSubmitting(true);

      try {
        await new AuthService({ errorsExpected: [400] }).changePassword(hash, {
          new: newPassword,
        });

        const message = 'auth.notifications.changePassword.success.desc';
        dispatch(gotFeedback({ message, type: 'success' }));
        setSubmitting(false);
      } catch (error) {
        if (AuthService.isAxiosError(error)) {
          if (error?.response?.status === 400) {
            const data = error.response
              .data as paths['/api/authority/account/password/recover/{hash}']['post']['responses']['400']['content']['application/json'];
            if (data.errors) {
              const errors = data.errors[0];
              const key = errors.errors[0];
              let msg = '';
              switch (key) {
                //@ts-expect-error Endpoint type issue: /api/authority/account/password/recover/{hash} Error:400 should include 'password_too_similar'
                case 'password_too_similar':
                  msg = intl.formatMessage(
                    //@ts-expect-error Endpoint type issue: /api/authority/account/password/recover/{hash} Error:400 should include 'password_too_similiar'
                    { id: key.toUpperCase() },
                    { name: VALUES_FOR_FEEDBACK[errors[key]] },
                  );
                  break;
                case 'invalid':
                  msg = intl.formatMessage({ id: 'PASSWORD_ALREADY_UPDATED' });
                  break;
                //@ts-expect-error Endpoint type issue: /api/authority/account/password/recover/{hash} Error:400 should include 'expired'
                case 'expired':
                  msg = intl.formatMessage({ id: 'RESET_PASSWORD_EXPIRED' });
                  break;
                //@ts-expect-error Endpoint type issue: /api/authority/account/password/recover/{hash} Error:400 should include 'password_in_history'
                case 'password_in_history':
                  msg = intl.formatMessage({ id: 'PASSWORD_RECENTLY_USED' });
                  break;
                //@ts-expect-error Endpoint type issue: /api/authority/account/password/recover/{hash} Error:400 should include 'password_too_common'
                case 'password_too_common':
                  msg = intl.formatMessage({ id: 'PASSWORD_TOO_COMMON' });
                  break;
                default:
                  msg = intl.formatMessage({ id: 'ERROR_SCREEN_HEADER' });
                  break;
              }
              dispatch(
                gotFeedback({
                  type: 'error',
                  message: msg,
                }),
              );
            }

            setSubmitting(false);
          } else {
            Logger.captureException(error.response);
            const message = 'auth.errors.error';
            dispatch(gotFeedback({ message, type: 'error' }));
            setSubmitting(false);
          }
        }
      }
    }
  };

  return loading ? (
    <LoadingPage />
  ) : (
    <SecondaryAuthPageContainer title="CHANGE_PASSWORD" description="CHANGE_PASSWORD_DESCRIPTION">
      <InputField
        size="large"
        label={intl.formatMessage({ id: 'NEW_PASSWORD' })}
        feedback={validations.newPassword}
        testId="new-password-field"
      >
        <>
          <PasswordInput
            {...referenceProps}
            name="newPassword"
            error={!!validations.newPassword}
            value={newPassword}
            onChange={(e) => handleInputChange(e.target.value, 'newPassword')}
            autoCompleteOff
            placeholder={intl.formatMessage({ id: 'NEW_PASSWORD_PLACEHOLDER' })}
            size="large"
            onBlur={() => {
              if (disabled) {
                setValidations({
                  ...validations,
                  newPassword: intl.formatMessage({ id: 'ERROR_PASSWORD_POLICY' }),
                });
              }
              referenceProps.onBlur();
            }}
            testId={TEST_IDS.INPUT.NEW_PASSWORD}
          />

          <Popover {...popperProps} testId="password-checker-popper">
            <PasswordCheck rules={rules} password={newPassword} setDisabled={handleSetDisabled} />
          </Popover>
        </>
      </InputField>
      <InputField
        size="large"
        label={intl.formatMessage({ id: 'CONFIRM_PASSWORD' })}
        feedback={validations.confirmedPassword}
        testId="confirm-password-field"
      >
        <PasswordInput
          name="confirmedPassword"
          size="large"
          value={confirmedPassword}
          onChange={(e) => handleInputChange(e.target.value, 'confirmedPassword')}
          placeholder={intl.formatMessage({ id: 'CONFIRM_PASSWORD_PLACEHOLDER' })}
          error={!!validations.confirmedPassword}
          onEnterKey={handleChangePassword}
          testId={TEST_IDS.INPUT.CONFIRM_PASSWORD}
        />
      </InputField>
      <Button
        variant="primary"
        fullWidth
        onClick={handleChangePassword}
        loading={submitting}
        disabled={newPassword === '' || disabled || newPassword !== confirmedPassword}
        size="large"
        testId={TEST_IDS.BUTTON}
        margin="1rem 0 2rem"
      >
        <FormattedMessage id="CHANGE_PASSWORD" />
      </Button>

      <Button
        size="medium"
        variant="link"
        onClick={goToSignIn}
        disabled={submitting}
        testId="sign-in-to-doDOC-button"
      >
        <FormattedMessage id="SIGN_IN_TO_DODOC" />
      </Button>
    </SecondaryAuthPageContainer>
  );
};

export default ResetPage;
