(function (arr) {
  arr.forEach((item) => {
    if (Object.prototype.hasOwnProperty.call(item, 'isInViewport')) {
      return;
    }
    Object.defineProperty(item, 'isInViewport', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function isInViewport() {
        const rect = this.getBoundingClientRect();
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight ||
              document.documentElement.clientHeight) /* or $(window).height() */ &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        );
      },
    });
  });
})([Element.prototype, DocumentType.prototype]);

(function (arr) {
  arr.forEach((item) => {
    if (Object.prototype.hasOwnProperty.call(item, 'isInEditorRootViewport')) {
      return;
    }
    Object.defineProperty(item, 'isInEditorRootViewport', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function isInEditorRootViewport() {
        const editorRootRect = document.getElementById('EditorRoot').getBoundingClientRect();
        const rect = this.getBoundingClientRect();
        return (
          rect.top >= editorRootRect.top &&
          rect.left >= editorRootRect.left &&
          rect.bottom <= editorRootRect.bottom /* or $(window).height() */ &&
          rect.right <= editorRootRect.right /* or $(window).width() */
        );
      },
    });
  });
})([Element.prototype, DocumentType.prototype]);
